jQuery(document).ready(function ($) {
    /*--------------------------------------------------------------
    1.0 - Navigation
    --------------------------------------------------------------*/
    /*--------------------------------------------------------------
    1.1 - Desktop
    --------------------------------------------------------------*/
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            $(".site-header").addClass("on-scroll");
        } else {
            $(".site-header").removeClass("on-scroll");
        }
    });

    /*--------------------------------------------------------------
    1.1.1 - Desktop sub-menu
    --------------------------------------------------------------*/
    $('.sub-menu').hide();

    $('.menu-item-has-children').hover(function () {
        $(this).addClass("sub-active");
        $(this).children('.sub-menu').finish().fadeIn("fast");
    }, function () {
        $(this).addClass("sub-active");
        $(this).children('.sub-menu').finish().fadeOut("fast");
    });

    /*--------------------------------------------------------------
    1.2 - Mobile / tablet
    --------------------------------------------------------------*/
    $("a.open-navigation, a.open-nav").click(function () {
        $("a.open-navigation").toggleClass("x");
    });

    //Exelent little functions to use any time when class modification is needed
    function hasClass(ele, cls) {
        return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    }

    function addClass(ele, cls) {
        if (!hasClass(ele, cls)) ele.className += " " + cls;
    }

    function removeClass(ele, cls) {
        if (hasClass(ele, cls)) {
            var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            ele.className = ele.className.replace(reg, ' ');
        }
    }

    //Add event from js the keep the marup clean
    function init() {
        document.getElementById("ham-nav").addEventListener("click", toggleMenu);
    }

    function toggleMenu() {
        var ele = document.getElementsByTagName('body')[0];
        if (!hasClass(ele, "open")) {
            addClass(ele, "open");
        } else {
            removeClass(ele, "open");
        }
    }

    document.addEventListener('readystatechange', function () {
        if (document.readyState === "complete") {
            init();
        }
    });

    /*--------------------------------------------------------------
    2.0 - Init sliders
    --------------------------------------------------------------*/
    $('.slider').slick({
        autoplay: false,
        speed: 1750,
        fade: true,
        lazyLoad: 'progressive',
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next'),
        dots: false,
    });

    $('.brands-slider').slick({
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
